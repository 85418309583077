import React, { useContext } from "react"
import { CartContext } from "./CartProvider"
import Checkout from "./Checkout"
import CartItemBig from "./CartItem"

const CartContentsBig = () => {
  const { cart, cartcount } = useContext(CartContext)
  return (
    <div className="cartcontentsbigwrap">
      <br />
      <br />
      <div className="yourcartheader">CART</div>
      {cartcount === 0 && (
        <span>
          <div className="cartnoitems">No items in cart.</div>
        </span>
      )}
      {cart.map((item) => (
        <CartItemBig key={item.price.id} item={item}/>
      ))}
      <div className="checkout">
        <Checkout />
      </div>
    </div>
  )
}

export default CartContentsBig
