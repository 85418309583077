import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import CartContentsBig from "../components/Cart/CartContentsBig"

const Submittedpage = ({ data }) => {
  return (
    <Layout classExtra="nofooter">
      <Helmet meta={[{ name: `robots`, content: "noindex" }]} />
      <div className="blogwrap">
        <div className="contactbackground" />
        <div className="formfloat">
          <CartContentsBig />
        </div>
      </div>
    </Layout>
  )
}

export default Submittedpage
